// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-code-js": () => import("./../../../src/pages/code.js" /* webpackChunkName: "component---src-pages-code-js" */),
  "component---src-pages-design-hilights-js": () => import("./../../../src/pages/design/hilights.js" /* webpackChunkName: "component---src-pages-design-hilights-js" */),
  "component---src-pages-design-idealist-js": () => import("./../../../src/pages/design/idealist.js" /* webpackChunkName: "component---src-pages-design-idealist-js" */),
  "component---src-pages-design-js": () => import("./../../../src/pages/design.js" /* webpackChunkName: "component---src-pages-design-js" */),
  "component---src-pages-design-lumen-js": () => import("./../../../src/pages/design/lumen.js" /* webpackChunkName: "component---src-pages-design-lumen-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-stories-aila-js": () => import("./../../../src/pages/stories/aila.js" /* webpackChunkName: "component---src-pages-stories-aila-js" */)
}

